export const config = {
    RPC_URL: 'https://rpc-osmosis-ia.cosmosia.notional.ventures',
    REST_URL: 'https://api-osmosis-ia.cosmosia.notional.ventures',
    EXPLORER_URL: 'https://www.mintscan.io/osmosis',
    STAKING_URL: 'https://osmosis.omniflix.co/stake',
    NETWORK_NAME: 'Osmosis',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'osmosis-1',
    CHAIN_NAME: 'Osmosis',
    COIN_DENOM: 'OSMO',
    COIN_MINIMAL_DENOM: 'uosmo',
    COIN_DECIMALS: 6,
    PREFIX: 'osmo',
    COIN_TYPE: 118,
    COSMOSTAION: 'osmosis',
    COINGECKO_ID: 'osmosis',
    GAS_PRICE_STEP_LOW: 0,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
